var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('div',{staticClass:"column is-7"},[_c('form',{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handlePasswordSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"senha-antiga","type":"password","label":"Senha Antiga","validation":_vm.$v.form.senhaAntiga,"error":{
						invalid: '*Campo inválido.',
						required: '*Campo obrigatório.',
					},"required":""},model:{value:(_vm.form.senhaAntiga),callback:function ($$v) {_vm.$set(_vm.form, "senhaAntiga", $$v)},expression:"form.senhaAntiga"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"nova-senha","type":"password","label":"Nova Senha","validation":_vm.$v.form.novaSenha,"error":{
						minLength: '*A quantidade de caracteres é menor que 8.',
						invalid: '*Campo inválido.',
						required: '*Campo obrigatório.',
						strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
					},"required":""},model:{value:(_vm.form.novaSenha),callback:function ($$v) {_vm.$set(_vm.form, "novaSenha", $$v)},expression:"form.novaSenha"}})],1),_vm._m(0),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"confirma-nova-senha","type":"password","label":"Confirma Nova Senha","validation":_vm.$v.form.confirmaNovaSenha,"error":{
						minLength: '*A quantidade de caracteres é menor que 8.',
						invalid: '*Campo inválido.',
						required: '*Campo obrigatório.',
						strong: '*A senha deve conter números e letras maiúsculas e minúsculas.',
						sameAs: '*A senha e a confirmação devem ser a mesma.'
					},"required":""},model:{value:(_vm.form.confirmaNovaSenha),callback:function ($$v) {_vm.$set(_vm.form, "confirmaNovaSenha", $$v)},expression:"form.confirmaNovaSenha"}})],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-5 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded is-outlined",attrs:{"type":"button"},on:{"click":_vm.handleCancel}},[_vm._v(" Cancelar ")])]),_vm._m(1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"content is-size-6"},[_vm._v(" Não é possível repetir a mesma senha das últimas 5 trocas de senha. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-7 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"submit"}},[_vm._v(" Atualizar senha ")])])}]

export { render, staticRenderFns }