<template>
	<div class="columns">
		<b-notification
			:closable="false"
			class="notification"
		>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>
		</b-notification>
		<transition name="fade">
			<div
				v-if="!loading"
				class="column is-10-tablet is-offset-1-tablet is-10-fullhd"
			>
				<Title class="mb-5">
					{{ activeTitle }}
				</Title>

				<div class="panel">
					<div class="panel-header">
						<b-tabs
							v-model="activeTab"
							@input="changeTitle"
						>
							<b-tab-item
								ref="tab-0"
								label="Dados Pessoais"
							/>
							<b-tab-item
								ref="tab-1"
								label="Endereço"
							/>
							<b-tab-item
								ref="tab-2"
								label="Senha"
							/>
						</b-tabs>
					</div>
					<transition name="fade">
						<div
							v-show="activeTab == 0"
							class="panel-content"
						>
							<DadosPessoaisForm
								:personal-data="personalData"
								:picture="picture"
							/>
						</div>
					</transition>
					<transition name="fade">
						<div
							v-show="activeTab == 1"
							class="panel-content"
						>
							<EnderecosForm :address="address" />
						</div>
					</transition>
					<transition name="fade">
						<div
							v-show="activeTab == 2"
							class="panel-content"
						>
							<SenhaForm />
						</div>
					</transition>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import http from '@commons/http';
import Title from '@components/Title';
import DadosPessoaisForm from '@components/dados_pessoais/DadosPessoaisForm';
import EnderecosForm from '@components/dados_pessoais/EnderecosForm';
import SenhaForm from '@components/dados_pessoais/SenhaForm';

export default {
	name: "DadosPessoais",
	components: {
		Title,
		DadosPessoaisForm,
		EnderecosForm,
		SenhaForm,
	},
	data(){
		return {
			loading: true,
			activeTab: 0,
			title: 'Dados Pessoais',
			personalData: {},
			address: {},
			password: {},
			enrollment: {}
		}
	},
	computed: {
		activeTitle() {
			return this.title;
		}
	},
	async created() {
		this.loading = true;
		await this.getServerData();

		const hash = this.$route.hash || null;

		switch (hash) {
			case '#senha':
				this.activeTab = 2;
				break;

			case '#enderecos':
				this.activeTab = 1;
				break;

			default:
				break;
		}
	},
	methods: {
		changeTitle(index) {
			this.title = this.$refs[`tab-${index}`].label;
		},
		async getServerData() {
			await http.get("/v1/aluno/dados-pessoais")
				.then((response)=> {
					this.parseServerData(response.data.message);
					this.loading = false;
				})
				.catch(() => {
					this.$buefy.toast.open({
						message: 'Erro ao consultar seus dados.',
						type: 'is-danger'
					});
				});
		},
		parseServerData(data) {
			this.personalData = {
				email: data.pess_email,
				celular: data.pess_fone_celular,
				paisPossuemFormacao: data.pess_primera_geracao_graduacao == 't' ? 'sim' : 'nao',
				empresa: data.pess_empresa,
				cargo: data.pess_cargo,
				urlLinkedin: data.pess_linkedin,
				urlFacebook: data.pess_facebook,
				corRaca: data.cora_id
			};

			this.picture = data.pess_caminho_foto;

			this.address = {
				cepResidencial: data.pess_cep,
				enderecoResidencial: data.pess_end,
				numeroResidencial: data.pess_end_numero,
				complementoResidencial: data.pess_end_compl,
				bairroResidencial: data.pess_end_bairro,
				cidadeResidencial: data.cida_id_endereco,
				estadoResidencial: "",
				paisResidencial: "",
				cepCobranca: data.pess_cep_cobranca,
				enderecoCobranca: data.pess_end_cobranca,
				numeroCobranca: data.pess_end_numero_cobranca,
				complementoCobranca: data.pess_end_compl_cobranca,
				bairroCobranca: data.pess_end_bairro_cobranca,
				cidadeCobranca: data.cida_id_cobranca,
				estadoCobranca: "",
				paisCobranca: "",
			};
		}
	}
}
</script>
