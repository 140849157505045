<template>
	<div class="columns is-7">
		<b-loading
			v-model="loading"
			:is-full-page="false"
			:can-cancel="false"
		/>

		<form
			novalidate
			class="form"
			@submit.prevent="handleEnderecosSubmit"
		>
			<div class="column">
				<h1 class="content is-size-4">
					Endereço Residencial
				</h1>
			</div>
			<div class="column is-12">
				<UIInput 
					id="cep-residencial"
					ref="cepResidencial"
					v-model="form.cepResidencial"
					v-mask="'#####-###'"
					label="CEP"
					maxlength="9"
					placeholder="CEP"
					type="text"
					:validation="$v.form.cepResidencial"
					:error="{
						required: '*Campo obrigatório.',
						minLength: '*CEP inválido'
					}"
					required
				/>
			</div>
			<div class="column is-12">
				<UIInput
					id="endereco-residencial"
					v-model="form.enderecoResidencial" 
					label="Endereço Residencial"
					placeholder="Endereço Residencial"
					type="text"
					:validation="$v.form.enderecoResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>
			</div>
			<div class="columns column mb-0">
				<UIInput
					id="numero-residencial"
					v-model="form.numeroResidencial"
					class="column is-4" 
					label="Número"
					placeholder="Número"
					type="text"
					:validation="$v.form.numeroResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>

				<UIInput 
					id="complemento-residencial"
					v-model="form.complementoResidencial"
					class="column is-8" 
					label="Complemento"
					placeholder="Complemento"
					type="text"
					:validation="$v.form.complementoResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>
			</div>
			<div class="column is-12">
				<UIInput 
					id="bairro-residencial"
					v-model="form.bairroResidencial" 
					label="Bairro"
					placeholder="Bairro"
					type="text"
					:validation="$v.form.bairroResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>
			</div>
			<div class="column is-12">
				<UIInput 
					id="cidade-residencial"
					v-model="form.cidadeResidencial"
					:disabled="true" 
					label="Cidade"
					placeholder="Cidade"
					type="text"
					:validation="$v.form.cidadeResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>
			</div>
			<div class="column is-12">
				<UIInput 
					id="estado-residencial"
					v-model="form.estadoResidencial"
					:disabled="true" 
					label="Estado"
					placeholder="Estado"
					type="text"
					:validation="$v.form.estadoResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>
			</div>
			<div class="column is-12">
				<UIInput 
					id="pais-residencial"
					v-model="form.paisResidencial"
					:disabled="true" 
					label="País"
					placeholder="País"
					type="text"
					:validation="$v.form.paisResidencial"
					:error="{
						required: '*Campo obrigatório.',
					}"
					required
				/>
			</div>
			<div class="column is-12">
				<b-field>
					<b-checkbox
						v-model="form.mesmoEnderecoCobranca"
						type="is-primary"
					>
						Meu endereço de cobrança é o mesmo do residencial
					</b-checkbox>
				</b-field>
			</div>

			<div v-show="! form.mesmoEnderecoCobranca">
				<div class="column">
					<h1 class="content is-size-4">
						Endereço de Cobrança
					</h1>
				</div>
				<div class="column is-12">
					<UIInput 
						id="cep-cobranca"
						ref="cepCobranca"
						v-model="form.cepCobranca"
						v-mask="'#####-###'"
						label="CEP"
						maxlength="9"
						placeholder="CEP"
						type="text"
						:validation="$v.form.cepCobranca"
						:error="{
							required: '*Campo obrigatório.',
							minLength: '*CEP inválido'
						}"
						required
					/>
				</div>
				<div class="column is-12">
					<UIInput
						id="endereco-cobranca"
						v-model="form.enderecoCobranca" 
						label="Endereço"
						placeholder="Endereço"
						type="text"
						:validation="$v.form.enderecoCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>
				<div class="columns column mb-0">
					<UIInput
						id="numero-cobranca"
						v-model="form.numeroCobranca"
						class="column is-4" 
						label="Número"
						placeholder="Número"
						type="text"
						:validation="$v.form.numeroCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>

					<UIInput 
						id="complemento-cobranca"
						v-model="form.complementoCobranca"
						class="column is-8" 
						label="Complemento"
						placeholder="Complemento"
						type="text"
						:validation="$v.form.complementoCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>
				<div class="column is-12">
					<UIInput 
						id="bairro-cobranca"
						v-model="form.bairroCobranca" 
						label="Bairro"
						placeholder="Bairro"
						type="text"
						:validation="$v.form.bairroCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>
				<div class="column is-12">
					<UIInput 
						id="cidade-cobranca"
						v-model="form.cidadeCobranca"
						:disabled="true" 
						label="Cidade"
						placeholder="Cidade"
						type="text"
						:validation="$v.form.cidadeCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>
				<div class="column is-12">
					<UIInput 
						id="estado-cobranca"
						v-model="form.estadoCobranca"
						:disabled="true" 
						label="Estado"
						placeholder="Estado"
						type="text"
						:validation="$v.form.estadoCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>
				<div class="column is-12">
					<UIInput 
						id="pais-cobranca"
						v-model="form.paisCobranca"
						:disabled="true" 
						label="País"
						placeholder="País"
						type="text"
						:validation="$v.form.paisCobranca"
						:error="{
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>
			</div>

			<div class="columns">
				<div class="column is-5 has-text-centered mt-4">
					<button
						type="button"
						class="button is-primary is-rounded is-outlined"
						@click="handleCancel"
					>
						Cancelar
					</button>
				</div>

				<div class="column is-7 has-text-centered mt-4">
					<button
						type="submit"
						class="button is-primary is-rounded"
					>
						Atualizar endereço
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import UIInput from '@components/uiinput';
import http from '@commons/http';
import { required, minLength } from 'vuelidate/lib/validators';
import { startCase, toLower } from "lodash";

export default {
	name: "EnderecosForm",
	components: {
		UIInput
	},
	props: {
		address: {
			type: [Object, Array],
			default: () => ({})
		}
	},
	data() {
		return {
			loading: false,
			form: {
				fullAddressResidencial: {},
				cepResidencial: "",
				enderecoResidencial: "",
				numeroResidencial: "",
				complementoResidencial: "",
				bairroResidencial: "",
				cidadeResidencial: "",
				estadoResidencial: "",
				paisResidencial: "",
				mesmoEnderecoCobranca: false,
				fullAddressCobranca: {},
				cepCobranca: "",
				enderecoCobranca: "",
				numeroCobranca: "",
				complementoCobranca: "",
				bairroCobranca: "",
				cidadeCobranca: "",
				estadoCobranca: "",
				paisCobranca: "",
			}
		}
	},
	watch: {
		async 'form.cepResidencial'(newCEP) {
			newCEP = newCEP.replace(/\D/g, "");

			if (newCEP.length == 8) {
				this.loading = true;
				await this.populateAddress(this.form.mesmoEnderecoCobranca, 'residencial');
			}

			this.loading = false;
		},
		async 'form.cepCobranca'(newCEP) {
			newCEP = newCEP.replace(/\D/g, "");

			if (newCEP.length == 8 && ! this.form.mesmoEnderecoCobranca) {
				this.loading = true;
				await this.populateAddress(this.form.mesmoEnderecoCobranca, 'cobranca');
			}

			this.loading = false;
		},
		'form.mesmoEnderecoCobranca'(checked) {
			if (checked) {
				this.replaceCobrancaDataWithResidencialData();
			}
		}
	},
	async created() {
		this.loading = true;

		Object.assign(this.form, this.address);
        
		this.form.mesmoEnderecoCobranca = this.isSameAddress(
			{ 
				cep: this.form.cepResidencial,
				numero: this.form.numeroResidencial 
			},
			{
				cep: this.form.cepCobranca,
				numero: this.form.numeroCobranca
			}
		);
	},
	methods: {
		handleEnderecosSubmit() {
			if (this.form.mesmoEnderecoCobranca) {
                this.replaceCobrancaDataWithResidencialData();
            }

			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.loading = false;
				return;
			}

			// Força "nextTick", por algum motivo se não fizer isso não detecta
            // a alteração da "loading", não mostrando o loader
            setTimeout(() => {
                this.loading = true;
				http.put("/v1/aluno/dados-pessoais", this.parseDataToSubmit())
					.then(() => {
						this.loading = false;
						this.$buefy.toast.open({
							message: 'Endereço atualizado com sucesso.',
							type: 'is-success'
						});
					}, () => {
						this.loading = false;
						this.$buefy.toast.open({
							message: 'Erro ao atualizar seu endereço.',
							type: 'is-danger'
						});
					});
			}, 1);
		},
		handleCancel() {
			this.loading = true;
			this.$router.push({name: 'home'});
		},
		async getFullAddress(cep) {
			cep = cep.replace(/\D/g, "");
			return await http.get("/v1/aluno/cep-address", {cep: cep});
		},
		isSameAddress(firstAddress, secondAddress) {
			if (
				firstAddress.cep != secondAddress.cep || 
				firstAddress.numero != secondAddress.numero
			) return false;

			return true;
		},
		async populateAddress(isSameAddress, addressType) {
			if (isSameAddress) {
				let addressTypes = ['residencial', 'cobranca'];

				await this.getFullAddress(this.form.cepResidencial)
					.then((response) => {
						let cepData = response.data.message;
                    
						addressTypes.forEach((addressType) => {
							addressType = startCase(toLower(addressType));

							Object.assign(this.form, {
								[`fullAddress${addressType}`]: cepData,
								[`endereco${addressType}`]: cepData.cep_endereco,
								[`bairro${addressType}`]: cepData.cep_bairro,
								[`cidade${addressType}`]: cepData.cep_cidade,
								[`estado${addressType}`]: cepData.esta_nome,
								[`pais${addressType}`]: cepData.pais_nome
							});
						});
					}, () => {
						addressTypes.forEach((addressType) => {
							addressType = startCase(toLower(addressType));

							Object.assign(this.form, {
								[`fullAddress${addressType}`]: {},
								[`endereco${addressType}`]: "",
								[`bairro${addressType}`]: "",
								[`cidade${addressType}`]: "",
								[`estado${addressType}`]: "",
								[`pais${addressType}`]: ""
							});
						});
                    
						this.$buefy.toast.open({
							message: 'CEP inválido.',
							type: 'is-danger'
						});
					});

				this.loading = false;
				return;
			}

			addressType = startCase(toLower(addressType));
			await this.getFullAddress(this.form[`cep${addressType}`])
				.then((response) => {
					let cepData = response.data.message
                
					Object.assign(this.form, {
						[`fullAddress${addressType}`]: cepData,
						[`endereco${addressType}`]: cepData.cep_endereco,
						[`bairro${addressType}`]: cepData.cep_bairro,
						[`cidade${addressType}`]: cepData.cep_cidade,
						[`estado${addressType}`]: cepData.esta_nome,
						[`pais${addressType}`]: cepData.pais_nome
					});
				}, () => {
					Object.assign(this.form, {
						[`fullAddress${addressType}`]: {},
						[`endereco${addressType}`]: "",
						[`bairro${addressType}`]: "",
						[`cidade${addressType}`]: "",
						[`estado${addressType}`]: "",
						[`pais${addressType}`]: ""
					});

					this.$buefy.toast.open({
						message: 'CEP inválido.',
						type: 'is-danger'
					});
				});

			this.loading = false;
		},
		parseDataToSubmit() {
			const data = {
				pess_cep: this.form.cepResidencial.replace(/\D/g, ""),
				pess_end: this.form.enderecoResidencial,
				pess_end_numero: this.form.numeroResidencial,
				pess_end_compl: this.form.complementoResidencial,
				pess_end_bairro: this.form.bairroResidencial,
				cida_id_endereco: this.form.fullAddressResidencial.cida_id,
				cida_nome_endereco: this.form.fullAddressResidencial.cep_cidade,
				pess_cep_cobranca: this.form.cepCobranca.replace(/\D/g, ""),
				pess_end_cobranca: this.form.enderecoCobranca,
				pess_end_numero_cobranca: this.form.numeroCobranca,
				pess_end_compl_cobranca: this.form.complementoCobranca,
				pess_end_bairro_cobranca: this.form.bairroCobranca,
				cida_id_cobranca: this.form.fullAddressCobranca.cida_id,
				cida_nome_cobranca: this.form.fullAddressCobranca.cep_cidade,
			};

			return new URLSearchParams(Object.entries(data)).toString();
		},
		replaceCobrancaDataWithResidencialData() {
			Object.assign(this.form, {
				fullAddressCobranca: this.form.fullAddressResidencial,
				enderecoCobranca: this.form.enderecoResidencial,
				bairroCobranca: this.form.bairroResidencial,
				cidadeCobranca: this.form.cidadeResidencial,
				estadoCobranca: this.form.estadoResidencial,
				paisCobranca: this.form.paisResidencial,
				cepCobranca: this.form.cepResidencial,
				complementoCobranca: this.form.complementoResidencial,
				numeroCobranca: this.form.numeroResidencial
			});
		}
	},
	validations:{
		form:{
			cepResidencial: {
				required,
				minLength: minLength(9)
			},
			enderecoResidencial: {
				required
			},
			bairroResidencial: {
				required
			},
			cidadeResidencial: {
				required
			},
			estadoResidencial: {
				required
			},
			paisResidencial: {
				required
			},
			cepCobranca: {
				required,
				minLength: minLength(9)
			},
			enderecoCobranca: {
				required
			},
			bairroCobranca: {
				required
			},
			cidadeCobranca: {
				required
			},
			estadoCobranca: {
				required
			},
			paisCobranca: {
				required
			},
		},
	},
}
</script>
