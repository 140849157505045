<template>
	<div class="columns">
		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<div class="column is-7">
			<form
				novalidate
				class="form"
				@submit.prevent="handlePasswordSubmit"
			>
				<div class="column is-12">
					<UIInput
						id="senha-antiga"
						v-model="form.senhaAntiga"
						type="password"
						label="Senha Antiga"
						:validation="$v.form.senhaAntiga"
						:error="{
							invalid: '*Campo inválido.',
							required: '*Campo obrigatório.',
						}"
						required
					/>
				</div>

				<div class="column is-12">
					<UIInput
						id="nova-senha"
						v-model="form.novaSenha"
						type="password"
						label="Nova Senha"
						:validation="$v.form.novaSenha"
						:error="{
							minLength: '*A quantidade de caracteres é menor que 8.',
							invalid: '*Campo inválido.',
							required: '*Campo obrigatório.',
							strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
						}"
						required
					/>
				</div>

				<div class="column is-12">
					<p class="content is-size-6">
						Não é possível repetir a mesma senha das últimas 5 trocas de senha.
					</p>
				</div>

				<div class="column is-12">
					<UIInput
						id="confirma-nova-senha"
						v-model="form.confirmaNovaSenha"
						type="password"
						label="Confirma Nova Senha"
						:validation="$v.form.confirmaNovaSenha"
						:error="{
							minLength: '*A quantidade de caracteres é menor que 8.',
							invalid: '*Campo inválido.',
							required: '*Campo obrigatório.',
							strong: '*A senha deve conter números e letras maiúsculas e minúsculas.',
							sameAs: '*A senha e a confirmação devem ser a mesma.'
						}"
						required
					/>
				</div>

				<div class="columns">
					<div class="column is-5 has-text-centered mt-4">
						<button
							type="button"
							class="button is-primary is-rounded is-outlined"
							@click="handleCancel"
						>
							Cancelar
						</button>
					</div>

					<div class="column is-7 has-text-centered mt-4">
						<button
							type="submit"
							class="button is-primary is-rounded"
						>
							Atualizar senha
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import UIInput from '@components/uiinput';
import http from '@commons/http';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { login } from '@commons/authenticate';
import { isStrongPassword } from '@commons/validations';

export default {
	name: "SenhaForm",
	components: {
		UIInput
	},
	data() {
		return {
			form: {
				senhaAntiga: "",
				novaSenha: "",
				confirmaNovaSenha: ""
			},
			loading: false
		}
	},
	methods: {
		async handlePasswordSubmit() {
			this.loading = true;
			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.loading = false;
				return;
			}

			let data = new URLSearchParams(
				Object.entries({
					current_pass: this.form.senhaAntiga,
					new_pass: this.form.novaSenha
				})
			).toString();

			await http.post("/v1/aluno/password", data)
				.then(() => {
					let loginData = new URLSearchParams(
						Object.entries({
							login: this.$store.state.alunoMatricula,
							senha: btoa(this.form.novaSenha),
							user_type: 'A'
						})
					);

					login(loginData).then((response) => {
						this.$store.commit('setToken', response.data.token);
					}).catch(() => {
						this.$buefy.toast.open({
							message: 'Erro ao atualizar o token.',
							type: 'is-danger'
						});
					});

					this.loading = false;
					this.$buefy.toast.open({
						message: 'Senha alterada com sucesso.',
						type: 'is-success'
					});
				}, (response) => {
					this.loading = false;
					this.$buefy.toast.open({
						message: response.data.message,
						type: 'is-danger'
					});
				});
		},
		handleCancel() {
			this.loading = true;
			this.$router.push({name: 'home'});
		}
	},
	validations:{
		form:{
			senhaAntiga: {
				required,
			},
			novaSenha: {
				required,
				minLength: minLength(8),
				isStrongPassword
			},
			confirmaNovaSenha: {
				sameAsPassword: sameAs('novaSenha'),
				required,
				minLength: minLength(8),
				isStrongPassword
			}
		},

	}
}
</script>

<style>

</style>
