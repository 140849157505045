<template>
	<div
		:class="[
			isMobile ? 'mobile-tooltip-size' : 'desktop-tooltip-size',
			'tooltip-vinculo',
			'is-flex',
			'is-align-items-center'
		]"
	>
		<b-tooltip
			:position="isMobile ? 'is-right' : 'is-bottom'"
			size="is-large"
			multilined
			type="is-light"
			square
			:triggers="['hover', 'click']"
		>
			<template #content>
				<p class="is-size-6 has-text-justified">
					<span class="is-relative">
						<b-icon
							class="is-relative warning-icon"
							icon="alert"
							size="is-small"
						/>
						<b-icon
							class="warning-icon-outline is-absolute"
							icon="alert-outline"
							size="is-small"
						/>
					</span>
					<strong>
						Você está usando o filtro de vínculo por curso e as informações
						desta página estão personalizadas, conforme o que você selecionou.
						Caso queira visualizar todos os seus cursos, acesse novamente o
						filtro, na opção 'Todos os Cursos'.</strong>
				</p>
			</template>
			<Icon
				class="mt-2 is-clickable"
				size="20"
				file="information"
			/>
		</b-tooltip>

		<b-dropdown
			aria-role="list"
			class="pl-4 dropdown-vinculo"
		>
			<template #trigger="{ active }">
				<button class="button is-flex is-justify-content-space-between">
					<span class="label pt-2">
						{{ vinculoSelecionadoNome | formatText }}
					</span>

					<div class="icon ml-4">
						<Icon
							class="pt-2"
							size="20"
							:file="active ? 'chevron-up' : 'chevron-down'"
						/>
					</div>
				</button>
			</template>

			<b-dropdown-item
				v-if="permiteFiltrarTodos"
				class="dropdown-item is-flex is-align-items-center is-clickable"
				@click="setVinculoSelecionado('Todos os Cursos')"
			>
				<Icon
					class="mt-1 ml-3"
					file="arrow-right-circle"
					size="25"
				/>
				<p class="pl-3">
					<strong>Todos os Cursos</strong>
				</p>
			</b-dropdown-item>
			<hr
				v-if="permiteFiltrarTodos"
				class="dropdown-divider"
			>

			<b-dropdown-item
				v-if="permiteFiltrarTodosAtivos"
				class="dropdown-item is-flex is-align-items-center is-clickable"
				@click="setVinculoSelecionado('Todos os Cursos Ativos')"
			>
				<Icon
					class="mt-1 ml-3"
					file="arrow-right-circle"
					size="25"
				/>
				<p class="pl-3">
					<strong>Todos os Cursos Ativos</strong>
				</p>
			</b-dropdown-item>
			<hr
				v-if="permiteFiltrarTodosAtivos"
				class="dropdown-divider"
			>

			<template v-for="(vinculosCategoria, vinculosNomeCategoria) in getVinculosFiltro">
				<template>
					<b-dropdown-item
						:key="vinculosNomeCategoria"
						custom
					>
						<p
							:key="vinculosNomeCategoria"
							class="is-size-7 pl-3"
						>
							<strong class="is-menu-informative"> {{ vinculosNomeCategoria }} </strong>
						</p>
					</b-dropdown-item>
				</template>

				<div
					v-for="(vinculo, index) in vinculosCategoria"
					:key="`ativo-${index}-${vinculosNomeCategoria}`"
					class="dropdown-item is-clickable"
					tabindex="0"
					@click="setVinculoSelecionado(vinculo);"
				>
					<b-dropdown-item class="pl-5">
						<strong> {{ vinculo.nens_nome | formatText }}: </strong>
						{{ vinculo.curs_nome | formatText }} {{ vinculo.data_inicio_curso | formatDate }}
					</b-dropdown-item>

					<hr class="dropdown-divider">
				</div>
			</template>
		</b-dropdown>

		<b-modal
			v-model="isModalActive"
			:can-cancel="false"
			:width="isMobile ? '80%' : '30%'"
		>
			<div class="card">
				<div class="card-content">
					<div class="content">
						<p class="is-size-6">
							<span class="is-relative">
								<h2 class="has-text-centered is-size-3 primary-color">Importante!</h2>
							</span>
							Você está usando o filtro de vínculo por curso e as informações
							desta página estão personalizadas, conforme o que você
							selecionou.
							<br>
							<br>
							Caso queira visualizar todos os seus cursos,
							acesse novamente o filtro, na opção 'Todos os Cursos'.
						</p>

						<button
							class="my-2 button is-primary is-rounded btn-ciente"
							@click="handleTermos"
						>
							Estou Ciente
						</button>
					</div>
				</div>
			</div>
		</b-modal>
		<b-loading
			v-model="isLoading"
			:is-full-page="true"
			:can-cancel="false"
		/>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import { isPlainObject, } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { capitalizeAll } from '@commons/helpers';
export default {
	name: 'FiltroVinculo',
	components: {
		Icon
	},
	filters: {
		formatText(value) {
			if (!value) return;

			return capitalizeAll(value);
		},
		formatDate(value) {
			return moment(value).format('MM/YYYY')
		}
	},
	data() {
		return {
			defaultLabel: 'Cursos',
			browserWidth: null,
		};
	},
	computed: {
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado',
			'permiteFiltrarTodos',
			'permiteFiltrarTodosAtivos',
			'getVinculosFiltro',
			'getAceiteTermosFiltro',
			'isLoading',
		]),
		...mapGetters('cursos', [
			'getCursos'
		]),
		...mapGetters('ui', {
			uiLoading: 'isLoading',
			isMobile: 'isMobile',
		}),
		vinculoSelecionadoNome() {
			const vinculoSelecionado = this.getVinculoSelecionado;

			try {
				if (isPlainObject(vinculoSelecionado)) {
					return vinculoSelecionado.curs_nome;
				}

				if (!isPlainObject(vinculoSelecionado) && this.permiteFiltrarTodos) {
					return vinculoSelecionado;
				}

				return this.defaultLabel;
			} catch (error) {
				return this.defaultLabel;
			}
		},
		isModalActive() {
			if (this.uiLoading) {
				return false;
			}

			return !this.getAceiteTermosFiltro;
		},
	},
	methods: {
		...mapActions('vinculosAcademicos', [
			'aceitarTermosFiltro',
			'setVinculoSelecionado'
		]),
		async handleTermos() {
			await this.aceitarTermosFiltro()
		},
	}
};
</script>

<style scoped lang="scss">
.card-content p {
	line-height: 2 !important;
}

.iu-input > .control {
	padding: 0 !important;
}

.warning-icon {
	color: #ffcc00;
	z-index: 2;
}

.warning-icon-outline.is-absolute {
	position: absolute;
	left: 0px;
	z-index: 1;
	top: 3px;
	color: black !important;
}

.is-menu-informative {
  color: #777 !important;
}

.btn-ciente {
  min-width: 100% !important;
}

::v-deep {
	.tooltip-vinculo .tooltip-content {
		background-color: #c4c4c4 !important;
		line-height: 2 !important;
	}

	.tooltip-vinculo .tooltip-content strong {
		color: #525252 !important;
	}

	.tooltip-vinculo.mobile-tooltip-size .tooltip-content {
		width: 300px !important;
		background-color: white !important;
	}

	.tooltip-vinculo.desktop-tooltip-size .tooltip-content {
		width: 600px !important;
	}

	.dropdown-vinculo, .dropdown-vinculo .dropdown-trigger {
		width: 100%;
		min-width: initial !important;
		box-shadow: none !important;
		border: none !important;
	}

	.dropdown-vinculo, .dropdown-vinculo .dropdown-trigger button {
		width: 100%;
		min-width: 100%;
	}

  .dropdown-vinculo .dropdown-item,
  .dropdown-vinculo .dropdown-item:hover,
  .dropdown-vinculo a.dropdown-item:hover {
    white-space: initial !important;
    color: black !important;
    background-color: white !important;
  }

  .dropdown-vinculo .label {
    overflow: hidden !important;
  }

  .dropdown-vinculo .dropdown-menu {
    padding-right: 0.25rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    z-index: 1000 !important;
  }
}
</style>
