<template>
	<div>
		<b-loading
			v-model="loading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<transition name="fade">
			<div class="columns">
				<div class="column is-3 is-centered">
					<UIImage :src="showImage" />
					<b-upload
						v-model="file"
						class="upload is-inline-block"
						@change.native="submitPicture"
					>
						<p class="mt-3 mb-3 has-text-centered has-text-weight-bold content is-medium upload-link">
							Alterar Foto
						</p>
					</b-upload>
					<p class="content is-small has-text-centered">
						Esta foto será utilizada em sua carteirinha de estudante e poderá ser acessada pelo seu professor.
					</p>
				</div>

				<div class="column is-offset-2 is-7">
					<form
						novalidate
						class="form"
						@submit.prevent="handlePersonalDataSubmit"
					>
						<div class="column is-12">
							<UIInput 
								id="email"
								v-model="form.email" 
								label="E-mail"
								placeholder="E-mail"
								type="email"
								:validation="$v.form.email"
								:error="{
									required: '*Campo obrigatório.',
									email: '*Email no formato inválido.'
								}"
								required
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="celular" 
								v-model="form.celular" 
								v-mask="'(##) #####-####'"
								label="Celular"
								type="phone"
								:minlength="11"
								placeholder="Celular"
								:validation="$v.form.celular"
								:error="{
									required: '*Campo obrigatório.',
									minLength: '*Número de celular inválido.',
								}"
								required
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="pais-formacao-superior" 
								v-model="form.paisPossuemFormacao"
								type="select"
								:options="[
									{ label: 'Sim', value: 'sim' },
									{ label: 'Não', value: 'nao' },
								]" 
								label="O seus pais possuem formação superior?"
								placeholder="Selecione"
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="empresa" 
								v-model="form.empresa" 
								label="Empresa"
								placeholder="Empresa"
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="cargo" 
								v-model="form.cargo" 
								label="Cargo"
								placeholder="Cargo"
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="url-linkedin" 
								v-model="form.urlLinkedin" 
								label="URL Linkedin"
								placeholder="URL Linkedin"
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="url-facebook" 
								v-model="form.urlFacebook" 
								label="URL Facebook"
								placeholder="URL Facebook"
							/>
						</div>
						<div class="column is-12">
							<UIInput 
								id="cor-raca" 
								v-model="form.corRaca" 
								label="Cor e Raça"
								placeholder="Selecione"
								type="select"
								:options="corRacas"
							/>
						</div>

						<div class="columns">
							<div class="column is-5 has-text-centered mt-4">
								<button
									type="button"
									class="button is-primary is-rounded is-outlined"
									@click="handleCancel"
								>
									Cancelar
								</button>
							</div>

							<div class="column is-7 has-text-centered mt-4">
								<button
									type="submit"
									class="button is-primary is-rounded"
								>
									Atualizar dados
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import UIInput from '@components/uiinput';
import UIImage from '@components/UIImage';
import http from '@commons/http';
import { required, email, minLength } from 'vuelidate/lib/validators';

const noPicture = 'person_no_picture.svg';

export default {
	name: "DadosPessoaisForm",
	components: {
		UIInput,
		UIImage
	},
	props: {
		personalData: {
			type: [ Object, Array ],
			default: () => ({})
		},
		picture: {
			type: [ String ],
			default: noPicture,
		}
	},
	data() {
		return {
			loading: false,
			corRacas: [
				{ label: "Branca", value: 1 },
				{ label: "Parda", value: 2 },
				{ label: "Preta", value: 3 },
				{ label: "Amarela", value: 4 },
				{ label: "Indígena", value: 5 },
				{ label: "Não Declarada", value: 6 },
			],
			form: {
				email: "",
				celular: "",
				paisPossuemFormacao: "",
				empresa: "",
				cargo: "",
				urlLinkedin: "",
				urlFacebook: "",
				corRaca: "",
			},
			studentPicture: "",
			file: null
		}
	},
	computed: {
		showImage() {
            if (!this.studentPicture) {
                return noPicture;
            }

			if (this.studentPicture.match(/^https?:\/\//)) {
                return this.studentPicture;
            }

            return window.location.protocol + '//' + window.location.hostname + this.studentPicture;
		}
	},
	created() {
		Object.assign(this.form, this.personalData);
		this.studentPicture = this.picture;
	},
	methods: {
		async handlePersonalDataSubmit() {
			this.loading = true;
			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.loading = false;
				return;
			}
            
			await http.put("/v1/aluno/dados-pessoais", this.parseDataToSubmit())
				.then(() => {
					this.loading = false;
					this.$buefy.toast.open({
						message: 'Dados atualizados com sucesso.',
						type: 'is-success'
					});
				}, () => {
					this.loading = false;
					this.$buefy.toast.open({
						message: 'Erro ao atualizar seus dados.',
						type: 'is-danger'
					});
				});
		},
		handleCancel() {
			this.loading = true;
			this.$router.push({name: 'home'});
		},
		parseDataToSubmit() {
			let data = {
				pess_email: this.form.email,
				pess_fone_celular: this.form.celular.replace(/\D/g, ""),
				pess_primera_geracao_graduacao: this.form.paisPossuemFormacao == 'nao' ? '': 'sim',
				pess_empresa: this.form.empresa,
				pess_cargo: this.form.cargo,
				pess_linkedin: this.form.urlLinkedin,
				pess_facebook: this.form.urlFacebook,
				cora_id: this.form.corRaca
			}

			return new URLSearchParams(Object.entries(data)).toString();
		},
		submitPicture() {
			this.loading = true;
			const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
 
            let error = false;

			if (! this.file || ! acceptedImageTypes.includes(this.file['type'])) {
                error = 'Formato de arquivo invalido';
            }

            if (this.file.size > 1024 * 1024) {
                error = 'Tamanho excedido, a imagem deve ser de menor que 1MB';
            }

            if (error) {
				this.loading = false;
				this.$buefy.toast.open({
					message: error,
					type: 'is-danger'
				});
				return;
			}

			let data = new FormData();
			data.append('foto', this.file);

			http.post('/v1/aluno/upload-image', data, { 
				'Content-Type': 'multipart/form-data' 
			})
				.then((response) => {
					this.loading = false;
					this.studentPicture = response.data.message;
				})
				.catch(response => {
                    this.loading = false;

                    let message = 'Erro ao atualizar sua imagem';
                    if (response.data.message.startsWith('Não foi possível salvar a imagem:')) {
                        message = response.data.message.replace('Não foi possível salvar a imagem:', '').trim();
                    }

                    this.$buefy.toast.open({
                        message: message,
                        type: 'is-danger'
                    });
				});
		}
	},
	validations:{
		form:{
			email: {
				required,
				email
			},
			celular: {
				required,
				minLength: minLength(15),
			}
		},
            
	}
}
</script>

<style scoped>
    .upload {
        height: 0;
        width: 100%;
    }

    .upload-link:hover {
        cursor: pointer;
    }
</style>
